<template>
  <div class="episode-container" @click.prevent="episodeClickHandler(item)">
    <div class="cols">
      <div class="episode-column-1">
        <div class="cols flex--main-end">
          <div class="flex-cell--static pad-h--1 text-right">
            <a class="inline episode-link" :href="getEpisodeUrl(item)" @click.stop.prevent="episodeClickHandler(item)"> #{{ item.id }} </a>
            <div class="mar-top--1">{{ $filters.formatDate(item.created_date) }}</div>
            <div>
              <span> {{ $filters.formatTimeHHMM(item.created_date) }} - </span>
              <span v-if="!item.open" class="episode-text">
                {{ $filters.formatTimeHHMM(item.closed_date || item.created_date) }}
              </span>
              <span v-else class="episode-text--live">
                {{ $filters.upperCase($tf('live')) }}
              </span>
            </div>
            <div class="episode-text text-tetriary text-s" :class="{ 'episode-text--highlight': animation.faceHighlight }">
              {{ $tf('events') }} {{ item.events_count }}
            </div>
          </div>
          <div class="episode-image-container">
            <div v-if="defaultEvent">
              <img
                :key="'event-' + i"
                :src="defaultEvent.thumbnail"
                class="episode-image-thumbnail"
                :class="{ 'episode-image-thumbnail--highlight': animation.faceHighlight }"
                v-for="i in eventsFakeArray"
                :style="getStyle(i)"
                @click.stop="(v) => showImage(defaultEvent.fullframe, getBox(defaultEvent))"
              />
            </div>
          </div>
        </div>
        <div class="text-right mar-top--1" v-if="!item.acknowledged">
          <event-reaction :model="'episodes'" :objects="objectType" :item="item"></event-reaction>
        </div>
      </div>
      <div class="flex-cell--static" style="width: 1rem"></div>
      <div class="episode-column-2">
        <div class="cols height-100 flex--main-start" v-if="!loading">
          <div class="episode-image-container flex-cell--static" v-if="matched_object">
            <img :src="matched_object.thumbnail" class="episode-image-thumbnail" @click.stop="(v) => showImage(matched_object.source_photo, null)" />
          </div>
          <div class="mar-h-1">
            <a v-if="card" :href="getCardUrl(card)" class="text-xl episode-link" @click.stop.prevent="cardClickHandler(card)">{{ card.name }}</a>
            <div class="episode-text--large" v-else>{{ $tf('no_matches') }}</div>
            <watch-lists-inline :ids="item.matched_lists"></watch-lists-inline>
            <template v-if="showCameraMetadata">
              <episode-camera-groups class="item__camera-groups" :camera-groups="item.camera_groups" />
              <episode-cameras :cameras="item.cameras" v-if="item.cameras.length" />
            </template>
          </div>
        </div>
      </div>
      <div class="episode-column-3">
        <div class="cols height-100 flex--main-start" v-if="!loading">
          <features v-for="item in features" :features="item.features" :objects-type="item.type" :header="false" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from '@/apps/common/lodash';
import EventReaction from '@/components/events/reaction';
import qs from 'qs';
import EpisodeCameraGroups from './episode-camera-groups.vue';
import EpisodeCameras from './episode-cameras.vue';
import Features from '../common/features.vue';
import { ApiUrls } from '@/services/constants';
import { dataServiceFactory } from '@/services/data.services';
import { ObjectsType } from '@/store/objects/get.module';
import WatchListsInline from '@/components/watch-lists/inline';
import { firstCase } from '@/apps/common/filters';

const Item = {
  Height: 168,
  AcknowledgedHeight: 120
};

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: { WatchListsInline, EventReaction, EpisodeCameras, EpisodeCameraGroups, Features },
  props: {
    item: Object,
    showCameraMetadata: { type: Boolean, default: true },
    type: { type: String, required: false }
  },
  data: function () {
    return {
      card: null,
      matched_object: null,
      loading: false,
      animation: {
        height: Item.AcknowledgedHeight,
        faceHighlight: false
      }
    };
  },
  computed: {
    cardPath() {
      return `/cards/${this.cardType}/${this.item.matched_card}`;
    },
    episodeEventsPath() {
      let filter = _.pickBy(Object.assign({}, this.$store.state.faces_events.filter.empty, { episode: this.item.id }), (v) => !!v);
      return `/events/${this.objectType}/filter/` + qs.stringify(filter, { sort: alphabeticalSort });
    },
    eventsFakeArray() {
      const count = Math.min(this.item.events_count || 1, 3);
      return new Array(count).fill(0).map((v, k) => k);
    },
    state() {
      return this.$store.state[`episodes/${this.cardType}`];
    },
    features() {
      switch (this.cardType) {
        case 'cars':
          return [{ features: this.item.car_features, type: ObjectsType.Cars }];
        case 'humans':
        default:
          const { face_features, body_features } = this.item;
          return [
            { features: face_features, type: ObjectsType.Faces },
            { features: body_features, type: ObjectsType.Bodies }
          ];
      }
    },
    defaultEvent() {
      return this.getEvent();
    },
    date() {
      return new Date(this.item.created_date).toISOString();
    },
    objectType() {
      return this.cardType === 'humans' ? (_.isEmpty(this.item.body_features) ? 'faces' : 'bodies') : 'cars';
    },
    cardType() {
      const { objects } = this.$route.params;
      return objects ?? this.type ?? 'humans';
    }
  },
  watch: {
    'item.matched_card': function (v, p) {
      this.loadCard();
      this.loadMatchedObject();
    },
    'item.events_count': function () {
      this.animation.faceHighlight = true;
      setTimeout(() => {
        this.animation.faceHighlight = false;
      }, 300);
    },
    'item.acknowledged': function (v, p) {
      this.animation.height = v ? Item.AcknowledgedHeight : Item.Height;
    }
  },
  created() {
    this.loadCard();
    this.loadMatchedObject();
    this.playEnterAnimation();
  },
  methods: {
    playEnterAnimation() {
      if (this.item.source === 'ws') {
        this.animation.height = 0;
        setTimeout(() => {
          this.animation.height = this.item.acknowledged ? Item.AcknowledgedHeight : Item.Height;
        }, 100);
      } else {
        this.animation.height = this.item.acknowledged ? Item.AcknowledgedHeight : Item.Height;
      }
    },
    getEvent() {
      const { last_face_event, last_body_event, last_car_event, best_face_event, best_body_event, best_car_event } = this.item;
      const last_event = last_face_event ?? last_body_event ?? last_car_event;
      const best_event = best_face_event ?? best_body_event ?? best_car_event;
      const event = this.item.open ? last_event : best_event;
      return event;
    },
    loadCard() {
      if (!this.item.matched_card) return Promise.resolve(null);
      const cardModule = dataServiceFactory.getService(this.cardType === 'humans' ? ApiUrls.HumanCards : ApiUrls.CarCards);
      return cardModule.get(this.item.matched_card).then((v) => {
        this.card = v;
      });
    },
    loadMatchedObject() {
      const { matched_face_event, matched_body_event, matched_car_event } = this.item;
      let promise = Promise.resolve(null);

      if (matched_face_event) {
        promise = this.getMatchedObjectPromise(matched_face_event, ObjectsType.Faces);
      } else if (matched_body_event) {
        promise = this.getMatchedObjectPromise(matched_body_event, ObjectsType.Bodies);
      } else if (matched_car_event) {
        promise = this.getMatchedObjectPromise(matched_car_event, ObjectsType.Cars);
      }

      promise.then((v) => {
        this.matched_object = v;
      });
    },
    getMatchedObjectPromise(value, type) {
      return value && typeof value === 'object'
        ? this.$store.dispatch(`getBatchObject${firstCase(type)}`, { id_in: value.matched_object })
        : Promise.resolve(null);
    },
    getStyle(i) {
      const padding = 10,
        imageSize = 120 - padding * i,
        opacity = Math.max(1 - i * 0.3, 0.1),
        count = Math.min(this.item.events_count, 3);

      return {
        opacity,
        right: i * padding * 0.5 + 'px',
        height: imageSize + 'px',
        width: imageSize + 'px',
        top: i * padding * 1.5 + 'px',
        'z-index': count - i
      };
    },
    getEpisodeUrl() {
      return `#${this.episodeEventsPath}`;
    },
    getCardUrl() {
      return `#${this.cardPath}`;
    },
    episodeClickHandler(v) {
      this.$router.push({ path: this.episodeEventsPath });
    },
    cardClickHandler(v) {
      this.$router.push({ path: this.cardPath });
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(event) {
      return {
        x: event.frame_coords_left,
        y: event.frame_coords_top,
        w: event.frame_coords_right - event.frame_coords_left,
        h: event.frame_coords_bottom - event.frame_coords_top
      };
    }
  }
};
</script>

<style lang="stylus">
.item__camera-groups {
  margin: 0.5rem 0 0.25rem 0;
}
.episode-column-1,
.episode-column-2,
.episode-column-3 {
  box-sizing: border-box;
}
.episode-column-1 {
  flex-basis 250px;
  flex-grow: 1;
  flex-shrink: 0;
}
.episode-column-2 {
  flex-basis 500px;
  flex-grow: 3;
  flex-shrink: 0;
}
.episode-column-3 {
  flex-basis 250px;
  flex-grow: 2;
  flex-shrink: 0;
}
</style>
