






























import _ from '@/apps/common/lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import TableNavigation from '@/components/tables/navigation.vue';
import EpisodeItem from './item.vue';
import PageLayout from '@/components/page/layout.vue';
import { carEpisodesModule, humanEpisodesModule } from '@/store';
import { EpisodeType, EpisodeTypeList } from '@/store/episodes';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';

@Component({
  components: {
    PageLayout,
    EpisodeItem,
    TableNavigation
  }
})
export default class PageEpisodes extends Vue {
  timeout?: number;
  tab = EpisodeType.Human;

  @Watch('tab')
  tabChangeHandler(name) {
    const routeTab = this.$route.params.objects.slice(0, -1);
    const filter = routeTab === name ? this.$route.params.filter : 'limit=10';
    this.$router.push({ name: 'episodes', params: { objects: `${name}s`, filter } });
    this.state.get();
  }

  get state(): BaseItemsStateModule<any, any> {
    try {
      switch (this.tab) {
        case EpisodeType.Car:
          return carEpisodesModule;
        case EpisodeType.Human:
        default:
          return humanEpisodesModule;
      }
    } catch (e) {
      return humanEpisodesModule;
    }
  }

  get stats() {
    return this.$store.state.stats.episodes[this.tab];
  }

  get tabs() {
    return EpisodeTypeList.map((i) => ({ name: i, i18n: `${i}_episodes` }));
  }

  get computedTabs() {
    return this.tabs.filter(({ name }) => {
      if (name === 'human') {
        return (this.$store.getters.enabledObjects.includes('bodies') || this.$store.getters.enabledObjects.includes('faces')) && this.$hasPermission('ffsecurity.view_humanepisode');
      } else if (name === 'car') {
        return this.$store.getters.enabledObjects.includes('cars') && this.$hasPermission('ffsecurity.view_carepisode');
      } else {
        return [];
      }
    });
  }

  created() {
    this.tab = this.$route.params.objects.slice(0, -1) as any as EpisodeType;
  }

  mounted() {
    this.$cache.enable(true);
    this.loadStatisticContinuously();
    this.state.get();
    this.state.playing = true;
  }

  loadStatisticContinuously() {
    this.$store.dispatch('getStats', 'episodes').then(() => {
      this.timeout = setTimeout(this.loadStatisticContinuously, 10000);
    });
  }

  beforeDestroy() {
    this.$cache.enable(false);
    clearTimeout(this.timeout);
  }

  togglePlaying() {
    this.state.playing = !this.state.playing;
    if (this.state.playing) {
      this.state.filter.current.created_date_lte = null;
    } else {
      this.state.filter.current.created_date_lte = new Date().toISOString();
    }
  }

  rowClick(item) {
    if (this.$store.state.app.key.ctrl) return;
    this.$router.push({ path: this.getItemLink(item) });
  }

  getItemLink(item) {
    return `/episodes/${this.tab}s/${encodeURIComponent(item.id)}/`;
  }
}

function computeItemsIdsMap(items) {
  return items.reduce((itemsMap, item) => _.set(itemsMap, item.id, item), {});
}
